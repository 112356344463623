<template>
  <p
    class="text-subtitle-2 text-sm-subtitle-1 text-md-h6 font-weight-light"
    style="line-height: 1.7 !important;"
  >
    <slot />
  </p>
</template>

<script>
  export default {
    name: 'BaseText',
  }
</script>
